/* src/products/ProductTile.css */

.product-tile {
	width: 200px;
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	align-items: center;
	margin-right: 10px;
	overflow: hidden;
	background-color: transparent;
	/* color: #fff; */
	position: relative;
  }
  
  .product-tile-image {
	width: 100%;
	height: auto;
	border-radius: 10px;
  }
  
  .checkmark {
	position: absolute;
	top: 8px;
	right: 8px;
  }
  
  .checkmark img {
	width: 30px;
	height: 30px;
  }
  
  .product-info {
	width: 100%;
	text-align: left;
	padding: 10px 0;
	border: none;
	/* color: #fff; */
  }
  
  .product-info-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  .product-info h3 {
	margin: 0;
	padding: 0;
	font-size: .85rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  
  .product-tile .product-info p {
	margin: 0;
	padding: 0;
	font-size: .85rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  